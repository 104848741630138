<template>
  <div>
    <GoodsReceiptStowing
      v-if="activeCommand && activeCommand.id"
      :active-command="activeCommand"
      @done="commandDone"
    />
    <GoodsReceiptReceiveBasket v-else @submit="getActiveCommand" />
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "ReceiptStowing",
  components: {
    GoodsReceiptReceiveBasket: () =>
      import("@/components/pos_goods/ReceiptReceiveBasket"),
    GoodsReceiptStowing: () => import("@/components/pos_goods/ReceiptStowing"),
  },
  data: () => ({
    activeCommand: null,
    isLoading: false,
  }),
  methods: {
    async getActiveCommand() {
      if (this.isLoading) {
        this.isLoading = false;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/receipt-get-active-command");
        this.activeCommand = { ...data };
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    commandDone() {
      this.activeCommand = null;
    },
  },
  mounted() {
    this.getActiveCommand();
  },
};
</script>

<style scoped></style>
